import { render, staticRenderFns } from "./TaskManagerList.vue?vue&type=template&id=2b06e315&scoped=true"
import script from "./TaskManagerList.vue?vue&type=script&lang=js"
export * from "./TaskManagerList.vue?vue&type=script&lang=js"
import style0 from "./TaskManagerList.vue?vue&type=style&index=0&id=2b06e315&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b06e315",
  null
  
)

export default component.exports