import MenuUno from "./components/MenuUno.vue";
import MenuDue from "./components/MenuDue.vue";
import TestList from "./components/TestList.vue";
import TestTabsScroll from "./components/TestTabsScroll.vue";

export default {
    MenuUno,
    MenuDue,
    TestList,
    TestTabsScroll,
};
