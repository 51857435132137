<template>
  <div style="display: inline-block" v-if="size === 'sm'">
    <!-- <b-button size="sm" class="no-text" variant="outline-info" @click="onOpenPaziente()">
      <i class="fas fa-user-injured"></i>
    </b-button> -->
    <b-button v-if="statoAttuale === 'ACCETTATO'" size="sm" class="no-text" variant="outline-info" @click="onOpenVisita()">
      <i class="far fa-edit"></i>
    </b-button>
    <b-button v-else size="sm" class="no-text" variant="outline-info" @click="onShowVisita()">
      <i class="fas fa-eye"></i>
    </b-button>
  </div>
  <div v-else>
    <!-- <b-button size="md" class="no-text" variant="outline-info" @click="onOpenPaziente()"> <i class="fas fa-user-injured"></i>Paziente </b-button> -->
    <b-button v-if="statoAttuale === 'ACCETTATO'" size="md" class="no-text" variant="outline-info" @click="onOpenVisita()"> <i class="far fa-edit"></i>Referta </b-button>
    <b-button v-else size="md" class="no-text" variant="outline-info" @click="onShowVisita()"> <i class="fas fa-eye"></i>Visualizza </b-button>
  </div>
</template>

<script>
export default {
  props: {
    idAmbulatorio: {
      type: String,
      required: true,
    },
    idPaziente: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    idAccettazione: {
      type: String,
      required: true,
    },
    statoAttuale: {
      type: String,
      required: true,
    },
    idEvento: {
      type: String,
      required: false,
    },
    prestazione: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      pathEvento: null,
    };
  },
  mounted() {
    let me = this;
    switch (me.prestazione) {
      case "SPIROMETRIA SEMPLICE":
        me.pathEvento = "paziente/esami/spirometria/";
        break;
      case "ELETTROCARDIOGRAMMA":
        me.pathEvento = "paziente/publipeas/visitaecg/";
        break;
      case "VISITA DERMATOLOGICA":
        me.pathEvento = "paziente/dermatologia/visitadermatologica/";
        break;
      default:
        me.pathEvento = "paziente/eventi/visita/";
        break;
    }
  },
  methods: {
    onOpenVisita() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage.setItem("idAmbulatorio", me.idAmbulatorio);
      // console.log("NuovaVisita");
      me.$router.replace(me.pathEvento + "edit/-1?idPaziente=" + me.idPaziente + "&idAccettazione=" + me.idAccettazione).catch((err) => {
        err;
      });
    },
    onOpenPaziente() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage.setItem("idAmbulatorio", me.idAmbulatorio);
      me.$router.replace("paziente/view/" + me.idPaziente).catch((err) => {
        err;
      });
    },
    onShowVisita() {
      let me = this;
      me.$router.replace(me.pathEvento + "view/" + me.idEvento + "?idPaziente=" + me.idPaziente).catch((err) => {
        err;
      });
    },
  },
};
</script>

<style></style>
