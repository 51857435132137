<template>
    <b-card class="sa-card" header="Identity Test" header-tag="header" footer-tag="footer" title="">
        <sa-flex-page>
            <template slot="header">
                <b-form @submit.prevent="onSubmit">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Username:</label>
                            <b-form-select v-model="jsonData.identity" :options="identityOptions" value-field="identity" text-field="identity"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Username:</label>
                            <b-form-input v-model="jsonData.username"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Password:</label>
                            <b-form-input v-model="jsonData.password"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Username Utente:</label>
                            <b-form-input v-model="jsonData.usernameUtente"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="sa-padding-right text-right">
                            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                            <b-button type="submit" variant="info">Esegui Test</b-button>
                            <iframe src="https://flussa.gesan.it/external/download/index.html?idFile=8a1823ae-334c-4fe7-8dc5-848f0e637ab3&flusso=sdo&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZFVzZXIiOiIwMzBjN2M1OS0wZjRjLTRmOWYtOGI3Yy1iOWQyNzQ4NDYzYWEiLCJpYXQiOjE3MTE2Mzc3MDl9.I4r_XLuUOktyYUPuIC41_vZHXkC0z4mj2GY2G8ub5Ag"></iframe>
                        </b-col>
                    </b-row>
                </b-form>
            </template>
            <template slot="body">
                <div style="display: flex; background-color: transparent; height: 100%; flex-direction: column">
                    <div><label class="sa-label-data">Response:</label></div>
                    <div style="flex: 1; background-color: rgb(247, 247, 247); border: 1px solid #b5b3b3; padding: 5px; border-radius: 5px">
                        <span v-html="response"></span>
                    </div>
                </div>
            </template>
        </sa-flex-page>
    </b-card>
</template>

<script>
//https://flussa.gesan.it/external/download/index.html?idFile=8a1823ae-334c-4fe7-8dc5-848f0e637ab3&flusso=sdo&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZFVzZXIiOiIwMzBjN2M1OS0wZjRjLTRmOWYtOGI3Yy1iOWQyNzQ4NDYzYWEiLCJpYXQiOjE3MTE2Mzc3MDl9.I4r_XLuUOktyYUPuIC41_vZHXkC0z4mj2GY2G8ub5Ag
import axios from "axios";
import SaFlexPage from "../../../template/layout/components/SaFlexPage.vue";
export default {
    components: { SaFlexPage },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/authentication",
            pathResourceTest: "/identityauthentication/userinfo",
            filtro: {},
            identityOptions: [],
            jsonData: {
                identity: null,
                username: null,
                password: null,
                role: "ALL",
            },
            response: "",
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.identityOptions = [];
                    me.identityOptions = response.data.data.list;
                    if (me.identityOptions.length === 1) {
                        me.jsonData.identity = me.identityOptions[0].identity;
                    }
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        getInfoAccount() {
            this.$emit("befoarLoadData");
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceTest;
            axios
                .post(link, this.jsonData)
                .then((response) => {
                    me.decodeResponse(response);
                    this.$emit("afterLoadData");
                })
                .catch((error) => {
                    me.decodeResponse(error);
                    this.$emit("afterLoadData");
                });
        },
        onSubmit() {
            let me = this;
            me.getInfoAccount();
        },
        onReset() {},
        decodeResponse(response) {
            let me = this;
            if (response.response) {
                if (response.response.data.constructor == String) {
                    me.response = "<span class='sa-response-error'>" + response.message + "</span>";
                    me.response += "<span>" + response.response.data + "</span>";
                } else if (response.response.data.constructor == Object) {
                    me.response = "<span class='sa-response-error'>" + response.message + "</span>";
                    me.response += "<span>Messaggio di Risposta: " + response.response.data.messaggio + "</span>";
                }
            } else {
                if (response.data.esito) {
                    me.response = "<span class='sa-response-success'>" + response.status + " - " + response.statusText + "</span>";
                    for (let key in response.data.data) {
                        me.response += "<span>" + key + "</span>: " + "<span><b>" + response.data.data[key] + "</b></span><br />";
                    }
                } else {
                    me.response = "<span class='sa-response-success'>" + response.status + " - " + response.statusText + " </span> ";
                    me.response += "<span>Messaggio di Risposta:</span> " + "<span class='sa-response-message'><b>" + response.data.messaggio + "</b></span><br />";
                }
            }
        },
    },
};
</script>

<style>
.sa-response-success {
    display: block;
    color: green;
}
.sa-response-error {
    display: block;
    color: red;
}
.sa-response-message {
    color: red;
}
</style>
