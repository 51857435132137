<template>
    <div class="sa-page-dashboard">
        <div class="scroller scroller-left" @click="onTabClickLeft"><i class="bi bi-chevron-left"></i></div>
        <div class="scroller scroller-right" @click="onTabClickRight"><i class="bi bi-chevron-right"></i></div>
        <b-tabs id="tabsSchede" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
            <b-tab title="First" active><p>I'm the first tab</p></b-tab>
            <b-tab title="Second"><p>I'm the second tab</p></b-tab>
            <b-tab title="Test 1"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 2"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 3"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 4"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 5"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 6"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 7"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 8"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 9"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 10"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 11"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 12"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 13"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Test 14"><p>I'm a disabled tab!</p></b-tab>
        </b-tabs>
    </div>
</template>

<script lang="js">
export default {
    methods: {
        onTabClickLeft() {
            let me = this;
            me.scrollTabs(+1);
        },
        onTabClickRight() {
            let me = this;
            me.scrollTabs(-1);
        },
        scrollTabs(direction) {
            //
            let leftButtonScrolling = document.querySelector(".scroller-left");
            let rightButtonScrolling = document.querySelector(".scroller-right");
            let tabElement = document.querySelector(".sa-tab-items");

            // let navTabs = document.querySelector("#tabsSchede").querySelector(".sa-tab-items");
            let navTabs = document.querySelector("#tabsSchede");
            let navItem = document.querySelector(".nav-item");
            let velocitaSpostamento = navItem.clientWidth + Math.floor(navItem.clientWidth / 100);
            let lunghezzaContenitore = navTabs.getBoundingClientRect().width;
            let lunghezzaTabElement = tabElement.getBoundingClientRect().width;
            let leftPosition = tabElement.offsetLeft + direction * velocitaSpostamento;
            if (leftPosition + lunghezzaTabElement < lunghezzaContenitore) {
                leftPosition = lunghezzaContenitore - lunghezzaTabElement;
            } else if (leftPosition > 0) {
                leftPosition = 0;
            }

            if (leftPosition === lunghezzaContenitore - lunghezzaTabElement) {
                rightButtonScrolling.style.visibility = "hidden";
            } else {
                rightButtonScrolling.style.visibility = "visible";
            }

            if (leftPosition === 0) {
                leftButtonScrolling.style.visibility = "hidden";
            } else {
                leftButtonScrolling.style.visibility = "visible";
            }
            tabElement.style.left = leftPosition + "px";
        },
    },
};
</script>

<style></style>
