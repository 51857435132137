<template>
  <sa-page-layout :pathResource="pathResource" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
    <!-- <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <span class="sa-label-data">Nome Indagine</span>
            <b-form-input v-model="filtro.nomeIndagine" type="search" id="malattia"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template> -->
    <template slot="table-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template #cell(dataPubblicazionePrevista)="{ item }">
            {{ formatDate(item.dataPubblicazionePrevista) }}
          </template>
          <template #cell(dataPubblicazione)="{ item }">
            {{ formatDateTime(item.dataPubblicazione) }}
          </template>
          <template #cell(isPubblicata)="{ item }">
            <b-icon v-if="item.isPubblicata" icon="check-square-fill" variant="success"></b-icon>
            <b-icon v-else icon="x-square-fill" variant="danger"></b-icon>
          </template>
          <!-- <template #cell(isPubblicata)="item">
            {{ formatDate(item.dataPubblicazione) }}
          </template> -->
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
// import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveiereports",
      showModalLoading: false,
      perPage: 50,
      currentPage: 1,
      stampaReport: null,
      filter: {},
      items: [],
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Nome Indagine",
          key: "indagine",
          sortable: true,
        },
        {
          label: "Data Prevista",
          key: "dataPubblicazionePrevista",
          sortable: true,
        },
        {
          label: "Data Pubblicazione",
          key: "dataPubblicazione",
          sortable: true,
        },
        {
          label: "Pubblicata",
          key: "isPubblicata",
          sortable: true,
        },
        // {
        //   label: "",
        //   key: "actions",
        //   thStyle: "width: 4rem",
        //   tdClass: "text-center",
        // },
      ],
      filtro: {
        nomeIndagine: "",
      },
    };
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?forPage=" + me.perPage + "&page=" + me.currentPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = response.data.data.list;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {
        nomeIndagine: "",
      };
      me.loadData();
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onVisualizzaModello(value) {
      let me = this;
      me.$refs.pdfPrintViewEmbedded.show();
      me.stampaReport = value.pdf.includes("data:application/pdf;base64,") ? value.pdf : "data:application/pdf;base64," + value.pdf;
    },
  },
};
</script>
