<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Diventato Giallo:</label>
          <b-form-select v-model="jsonData.malattiaDiventatoGiallo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Inizio Malattia:</label>
          <date-picker v-model="jsonData.dataInizioMalattia" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ospedalizzato:</label>
          <b-form-select v-model="jsonData.ospedalizzatoPerMalattia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ospedalizzato Durata:</label>
          <!-- <b-form-select v-model="jsonData.ospedalizzatoPerMalattia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
          <b-form-input v-model="jsonData.ospedalizzatoPerMalattiaGiorni" :disabled="chekCondition(jsonData.ospedalizzatoPerMalattia, 'SI')"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Durante le 6 settimane precedenti" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Assunzione Farmaci:</label>
          <b-form-select v-model="jsonData.seiSettimanePrimaAssunzioneFarmaci" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" @input="onAttivaTabella"></b-form-select>
        </b-col>
      </b-row>
      <b-row style="margin-top: 5px">
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header ref="tblFarmaci" stacked="xl" striped hover itemscope :items="jsonData.listaFarmaci" :fields="fieldsFarmaci" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="listaFarmaci">
              {{ listaFarmaci.index + 1 }}
            </template>
            <template #cell(farmaco)="item">
              <b-form-input v-model="item.item.farmaco" placeholder="Farmaco"></b-form-input>
            </template>
            <template #cell(tempo)="item">
              <b-form-input v-model="item.item.tempo" placeholder="Tempo"></b-form-input>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.listaFarmaci.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFarmaci(jsonData.listaFarmaci, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.listaFarmaci.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaFarmaci, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Consumato Frutti di Mare:</label>
          <b-form-select v-model="jsonData.seiSettimanePrimaConsumoFruttiMare" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Totalmente Crudi/in Parte:</label>
          <b-form-select v-model="jsonData.seiSettimanePrimaConsumoFruttiMareCrudi" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiSettimanePrimaConsumoFruttiMare, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Consumato Frutti di Bosco:</label>
          <b-form-select v-model="jsonData.seiSettimanePrimaConsumoFruttiBosco" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipo Frutti di Bosco:</label>
          <b-form-select v-model="jsonData.seiSettimanePrimaConsumoFruttiBoscoDettagli" :options="tipoFruttiBoscoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiSettimanePrimaConsumoFruttiBosco, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Bevuto Acqua di Pozzo/Soergente:</label>
          <b-form-select v-model="jsonData.seiSettimanePrimaConsumoAcquaPozzoSorgente" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Una/piu' Notte/i fuori Citta:</label>
          <b-form-select v-model="jsonData.seiSettimanePrimaNotteFuoriCitta" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dove:</label>
          <b-form-input v-model="jsonData.seiSettimanePrimaNotteFuoriCittaDove" :disabled="chekCondition(jsonData.seiSettimanePrimaNotteFuoriCitta, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Quando:</label>
          <date-picker v-model="jsonData.seiSettimanePrimaNotteFuoriCittaQuando" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.seiSettimanePrimaNotteFuoriCitta, 'SI')"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Vaccino Epatite A" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Somministrata Almeno Una Dose:</label>
          <b-form-select v-model="jsonData.doseVaccinoEpatiteA" :options="doseVaccinoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Vaccino Prima Dose:</label>
          <b-form-input v-model="jsonData.doseVaccinoEpatiteAPrimaDoseTipo" :disabled="checkDoubleCondition(jsonData.doseVaccinoEpatiteA, 'SI, 1 DOSE', 'SI, 2 DOSI', 'OR')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data Prima Dose:</label>
          <date-picker v-model="jsonData.doseVaccinoEpatiteAPrimaDoseData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="checkDoubleCondition(jsonData.doseVaccinoEpatiteA, 'SI, 1 DOSE', 'SI, 2 DOSI', 'OR')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Vaccino Seconda Dose:</label>
          <b-form-input v-model="jsonData.doseVaccinoEpatiteASecondaDoseTipo" :disabled="chekCondition(jsonData.doseVaccinoEpatiteA, 'SI, 2 DOSI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data Seconda Dose:</label>
          <date-picker v-model="jsonData.doseVaccinoEpatiteASecondaDoseData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.doseVaccinoEpatiteA, 'SI, 2 DOSI')"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Durante i 6 mesi precedenti" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Somministrazione Sangue/Plasma:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaSomministrazioneSanguePlasma" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Somministrazione Altri Derivati:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaSomministrazioneAltriDerivati" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Interventi Chirurgici:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaInterventiChirurgici" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Intervento:</label>
          <b-form-input v-model="jsonData.seiMesiPrimaInterventiChirurgiciTipo" :disabled="chekCondition(jsonData.seiMesiPrimaInterventiChirurgici, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Luogo Intervento:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaInterventiChirurgiciLuogo" :options="luogoInterventoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaInterventiChirurgici, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Endoscopia:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaEcoendoscopia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Endoscopia:</label>
          <b-form-input v-model="jsonData.seiMesiPrimaEcoendoscopiaTipo" :disabled="chekCondition(jsonData.seiMesiPrimaEcoendoscopia, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Luogo Endoscopia:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaEcoendoscopiaLuogo" :options="luogoInterventoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaEcoendoscopia, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ospedalizzato:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaOspedalizzato" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ospedale:</label>
          <b-form-input v-model="jsonData.seiMesiPrimaOspedalizzatoOspedale" :disabled="chekCondition(jsonData.seiMesiPrimaOspedalizzato, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Reparto:</label>
          <b-form-input v-model="jsonData.seiMesiPrimaOspedalizzatoReparto" :disabled="chekCondition(jsonData.seiMesiPrimaOspedalizzato, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data:</label>
          <date-picker v-model="jsonData.seiMesiPrimaOspedalizzatoData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.seiMesiPrimaOspedalizzato, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Uso Droghe:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaUsoDroga" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Modalita Assunzione:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaUsoDrogaModalitaAssunzione" :options="assunzioneDrogheOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaUsoDroga, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Contatto SERT:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaUsoDrogaContattoSert" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaUsoDroga, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Trattamento Dentario:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaTrattamentoDentario" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Buchi Orecchie/Piercing:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaPiercing" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tatuaggi:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaTatuaggio" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pratica Agopuntura:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaAgopuntura" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Rasato dal Barbiere:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaRasatoBarbiere" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Callista/Manicure:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaCallistaManicure" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Emodializzato:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaEmodializzato" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Vaccino Epatite B" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Somministrata Almeno Una Dose:</label>
          <b-form-select v-model="jsonData.doseVaccinoEpatiteB" :options="doseVaccinoBOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Vaccino Prima Dose:</label>
          <b-form-input v-model="jsonData.doseVaccinoEpatiteBPrimaDoseTipo" :disabled="!checkDoubleCondition(jsonData.doseVaccinoEpatiteB, 'NO', '', 'OR')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data Prima Dose:</label>
          <date-picker v-model="jsonData.doseVaccinoEpatiteBPrimaDoseData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="!checkDoubleCondition(jsonData.doseVaccinoEpatiteB, 'NO', '', 'OR')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Vaccino Seconda Dose:</label>
          <b-form-input v-model="jsonData.doseVaccinoEpatiteBSecondaDoseTipo" :disabled="checkDoubleCondition(jsonData.doseVaccinoEpatiteB, 'SI, 2 DOSI', 'SI, 3 DOSI', 'OR')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data Seconda Dose:</label>
          <date-picker v-model="jsonData.doseVaccinoEpatiteBSecondaDoseData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="checkDoubleCondition(jsonData.doseVaccinoEpatiteB, 'SI, 2 DOSI', 'SI, 3 DOSI', 'OR')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Vaccino Terza Dose:</label>
          <b-form-input v-model="jsonData.doseVaccinoEpatiteBTerzaDoseTipo" :disabled="chekCondition(jsonData.doseVaccinoEpatiteB, 'SI, 3 DOSI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data Terza Dose:</label>
          <date-picker v-model="jsonData.doseVaccinoEpatiteBTerzaDoseData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.doseVaccinoEpatiteB, 'SI, 3 DOSI')"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Contatto Persone con Epatite 6 Mesi Prima:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaContattoConoscentiInfetti" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malattia Iniziata Meno di Due Mesi Prima:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaContattoConoscentiInfettiInizioDueMesi" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaContattoConoscentiInfetti, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Conviventi/Pratner Sessuali HbsAg Positivo:</label>
          <b-form-select v-model="jsonData.conviventePatnerSessualeHbsagPositivo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Relazione:</label>
          <b-form-input v-model="jsonData.conviventePatnerSessualeHbsagPositivoTipoRelazione" :disabled="chekCondition(jsonData.conviventePatnerSessualeHbsagPositivo, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">A Conoscenza:</label>
          <b-form-select v-model="jsonData.conviventePatnerSessualeHbsagPositivoConoscenza" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.conviventePatnerSessualeHbsagPositivo, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Motivo Mancata Vaccinazione:</label>
          <b-form-select v-model="jsonData.conviventePatnerSessualeHbsagPositivoConoscenzaVaccino" :options="motivoMancataVaccinazioneOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.conviventePatnerSessualeHbsagPositivoConoscenza, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altro</label>
          <b-form-textarea v-model="jsonData.conviventePatnerSessualeHbsagPositivoConoscenzaVaccinoAl" rows="8" no-resize :disabled="chekCondition(jsonData.conviventePatnerSessualeHbsagPositivoConoscenzaVaccino, 'ALTRO')"></b-form-textarea>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Conviventi/Pratner Sessuali anti-HCV Positivo:</label>
          <b-form-select v-model="jsonData.conviventePatnerSessualeAntiHcvPisitivo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Convivente Tossicodipentente:</label>
          <b-form-select v-model="jsonData.conviventeTossicodipendente" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Partner Sessuali 6 mesi precedenti:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaPartnerSessuali" :options="siNoNonRispondoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Partner:</label>
          <b-form-input type="number" v-model="jsonData.seiMesiPrimaPartnerSessualiQuanti" :disabled="chekCondition(jsonData.seiMesiPrimaPartnerSessuali, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Partner Sessuale Tossicodipentente 6 mesi precedenti:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaPartnerSessualiTossicodipendenti" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Rapporti Occasionali 6 mesi precedenti:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaPartnerSessualiOccasionali" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Uso Profilattico:</label>
          <b-form-select v-model="jsonData.seiMesiPrimaPartnerSessualiOccasionaliProfilattico" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaPartnerSessualiOccasionali, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Persone in Casa:</label>
          <b-form-input type="number" v-model="jsonData.numeroPersoneCasa"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Presenti Bambino che frequentano Asilo/Scuola Materna:</label>
          <b-form-select v-model="jsonData.bambiniFrequentanoAsiloNodo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Donatore Periodico di Sangue:</label>
          <b-form-select v-model="jsonData.donatorePeriodicoSangue" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">In Passato Eseguito Test Hiv:</label>
          <b-form-select v-model="jsonData.testHivPassato" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Risutato Positivo:</label>
          <b-form-select v-model="jsonData.testHivPassatoRisultatoPositivo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.testHivPassato, 'SI')"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Valori Massimi Esami Laboratorio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">AST:</label>
          <b-form-input type="number" v-model="jsonData.valoriMassimiEsameAst"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">ALT:</label>
          <b-form-input type="number" v-model="jsonData.valoriMassimiEsameAlt"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Bilirubina Totale:</label>
          <b-form-input type="number" v-model="jsonData.valoriMassimiEsameBilirubinaTotale"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Bilirubina Diretta:</label>
          <b-form-input type="number" v-model="jsonData.valoriMassimiEsameBilirubinaDiretta"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">INR:</label>
          <b-form-input type="number" v-model="jsonData.valoriMassimiEsameInr"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Risultati di Laboratorio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Prelievo:</label>
          <date-picker v-model="jsonData.dataPrelievoSangue" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Positivo HbsAg:</label>
          <b-form-select v-model="jsonData.positivoHbsag" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Positivo IgM anti-HBC:</label>
          <b-form-select v-model="jsonData.positivoIgmAntiHbc" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Positivo IgM anti-epatite A:</label>
          <b-form-select v-model="jsonData.positivoIgmAntiEpatiteA" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Positivo IgM anti-delta:</label>
          <b-form-select v-model="jsonData.positivoIgmAntiDelta" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Positivo anti-HCV:</label>
          <b-form-select v-model="jsonData.positivoAntiHcv" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Positivo HCV-RNA:</label>
          <b-form-select v-model="jsonData.positivoHcvRna" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Livello Viremia:</label>
          <b-form-input v-model="jsonData.positivoHcvRnaLivelloViremia" :disabled="chekCondition(jsonData.positivoHcvRna, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Positivo IGM anti-Epatite E:</label>
          <b-form-select v-model="jsonData.positivoIgmAntiEpatiteE" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Decorso Clinico" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Encefalopatia:</label>
          <b-form-select v-model="jsonData.encefalopatia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Fulminante:</label>
          <b-form-select v-model="jsonData.fullminante" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Trapianto di Fegato:</label>
          <b-form-select v-model="jsonData.trapiantoFegato" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Decesso:</label>
          <b-form-select v-model="jsonData.decesso" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Decesso:</label>
          <date-picker v-model="jsonData.decessoData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.decesso, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Causa Iniziale Decesso:</label>
          <b-form-input v-model="jsonData.decessoCausaIniziale" :disabled="chekCondition(jsonData.decesso, 'SI')"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { IndagineEpidemiologicaDatiNotificaComponent, DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveieepatiti",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      linkback: null,
      currentPage: 1,
      perPage: 100,
      showModalLoading: false,
      linkNazioni: "/nazioni",
      disableSintomi: true,
      jsonData: {
        malattiaDiventatoGiallo: "",
        dataInizioMalattia: null,
        ospedalizzatoPerMalattia: "",
        ospedalizzatoPerMalattiaGiorni: "",
        seiSettimanePrimaAssunzioneFarmaci: "",
        seiSettimanePrimaConsumoFruttiMare: "",
        seiSettimanePrimaConsumoFruttiMareCrudi: "",
        seiSettimanePrimaConsumoFruttiBosco: "",
        seiSettimanePrimaConsumoFruttiBoscoDettagli: "",
        seiSettimanePrimaConsumoAcquaPozzoSorgente: "",
        seiSettimanePrimaNotteFuoriCitta: "",
        seiSettimanePrimaNotteFuoriCittaDove: "",
        seiSettimanePrimaNotteFuoriCittaQuando: null,
        doseVaccinoEpatiteA: "",
        doseVaccinoEpatiteAPrimaDoseTipo: "",
        doseVaccinoEpatiteAPrimaDoseData: null,
        doseVaccinoEpatiteASecondaDoseTipo: "",
        doseVaccinoEpatiteASecondaDoseData: null,
        seiMesiPrimaSomministrazioneSanguePlasma: "",
        seiMesiPrimaSomministrazioneAltriDerivati: "",
        seiMesiPrimaInterventiChirurgici: "",
        seiMesiPrimaInterventiChirurgiciTipo: "",
        seiMesiPrimaInterventiChirurgiciLuogo: "",
        seiMesiPrimaEcoendoscopia: "",
        seiMesiPrimaEcoendoscopiaTipo: "",
        seiMesiPrimaEcoendoscopiaLuogo: "",
        seiMesiPrimaOspedalizzato: "",
        seiMesiPrimaOspedalizzatoOspedale: "",
        seiMesiPrimaOspedalizzatoReparto: "",
        seiMesiPrimaOspedalizzatoData: null,
        seiMesiPrimaUsoDroga: "",
        seiMesiPrimaUsoDrogaModalitaAssunzione: "",
        seiMesiPrimaUsoDrogaContattoSert: "",
        seiMesiPrimaTrattamentoDentario: "",
        seiMesiPrimaPiercing: "",
        seiMesiPrimaTatuaggio: "",
        seiMesiPrimaAgopuntura: "",
        seiMesiPrimaRasatoBarbiere: "",
        seiMesiPrimaCallistaManicure: "",
        seiMesiPrimaEmodializzato: "",
        doseVaccinoEpatiteB: "",
        doseVaccinoEpatiteBPrimaDoseTipo: "",
        doseVaccinoEpatiteBPrimaDoseData: null,
        doseVaccinoEpatiteBSecondaDoseTipo: "",
        doseVaccinoEpatiteBSecondaDoseData: null,
        doseVaccinoEpatiteBTerzaDoseTipo: "",
        doseVaccinoEpatiteBTerzaDoseData: null,
        seiMesiPrimaContattoConoscentiInfetti: "",
        seiMesiPrimaContattoConoscentiInfettiInizioDueMesi: "",
        conviventePatnerSessualeHbsagPositivo: "",
        conviventePatnerSessualeHbsagPositivoTipoRelazione: "",
        conviventePatnerSessualeHbsagPositivoConoscenza: "",
        conviventePatnerSessualeHbsagPositivoConoscenzaVaccino: "",
        conviventePatnerSessualeHbsagPositivoConoscenzaVaccinoAl: "",
        conviventePatnerSessualeAntiHcvPisitivo: "",
        conviventeTossicodipendente: "",
        seiMesiPrimaPartnerSessuali: "",
        seiMesiPrimaPartnerSessualiQuanti: "",
        seiMesiPrimaPartnerSessualiTossicodipendenti: "",
        seiMesiPrimaPartnerSessualiOccasionali: "",
        seiMesiPrimaPartnerSessualiOccasionaliProfilattico: "",
        numeroPersoneCasa: "",
        bambiniFrequentanoAsiloNodo: "",
        donatorePeriodicoSangue: "",
        testHivPassato: "",
        testHivPassatoRisultatoPositivo: "",
        valoriMassimiEsameAst: "",
        valoriMassimiEsameAlt: "",
        valoriMassimiEsameBilirubinaTotale: "",
        valoriMassimiEsameBilirubinaDiretta: "",
        valoriMassimiEsameInr: "",
        dataPrelievoSangue: null,
        positivoHbsag: "",
        positivoIgmAntiHbc: "",
        positivoIgmAntiEpatiteA: "",
        positivoIgmAntiDelta: "",
        positivoAntiHcv: "",
        positivoHcvRna: "",
        positivoHcvRnaLivelloViremia: "",
        positivoIgmAntiEpatiteE: "",
        encefalopatia: "",
        fullminante: "",
        trapiantoFegato: "",
        decesso: "",
        decessoData: null,
        decessoCausaIniziale: "",
        sanitarioNotificante: "",
        sanitarioNotificanteContatto: "",
        dataNotifica: null,
        listaFarmaci: [],
      },
      fieldsFarmaci: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Farmaco",
          key: "farmaco",
          sortable: false,
        },
        {
          label: "Tempo",
          key: "tempo",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      nazioniOptions: [],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      siNoNonNotoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      siNoNonRispondoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "PREFERISCO NON RISPONDERE", text: "PREFERISCO NON RISPONDERE" },
      ],
      usoProfilatticoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SEMPRE", text: "SEMPRE" },
        { value: "OCCASIONALMENTE", text: "OCCASIONALMENTE" },
        { value: "MAI", text: "MAI" },
      ],
      tipoFruttiBoscoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "CONGELATI", text: "CONGELATI" },
        { value: "FRESCHI", text: "FRESCHI" },
        { value: "CONGELATI E FRESCHI", text: "ENTRCONGELATI E FRESCHIAMBI" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      doseVaccinoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI, 1 DOSE", text: "SI, 1 DOSE" },
        { value: "SI, 2 DOSI", text: "SI, 2 DOSI" },
        { value: "NO", text: "NO" },
      ],
      doseVaccinoBOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI, 1 DOSE", text: "SI, 1 DOSE" },
        { value: "SI, 2 DOSI", text: "SI, 2 DOSI" },
        { value: "SI, 3 DOSI", text: "SI, 3 DOSI" },
        { value: "NO", text: "NO" },
      ],
      luogoInterventoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "AMBULATORIO", text: "AMBULATORIO" },
        { value: "CON RICOVERO", text: "CON RICOVERO" },
      ],
      assunzioneDrogheOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "ENDOVENA", text: "ENDOVENA" },
        { value: "INALAZIONE", text: "INALAZIONE" },
        { value: "ENDOVENA E INALAZIONE", text: "ENDOVENA E INALAZIONE" },
        { value: "ALTRO", text: "ALTRO" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      motivoMancataVaccinazioneOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "NON ERO SICURO DELL’EFFICACIA ", text: "NON ERO SICURO DELL’EFFICACIA " },
        { value: "PAURA DEGLI EFFETTI COLLATERALI", text: "PAURA DEGLI EFFETTI COLLATERALI" },
        { value: "NON SAPEVA A QUALE STRUTTURA RIVOLGERSI", text: "NON SAPEVA A QUALE STRUTTURA RIVOLGERSI" },
        { value: "ALTRO", text: "ALTRO" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.jsonData.id = me.id;
    me.loadMalattiaInfettivaSegnalazione();
    me.loadNazioni();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.setDatiMedico();
          me.showModalLoading = false;
        });
    },
    loadMalattiaInfettivaSegnalazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.setDatiMalattia(response.data.data);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    loadNazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkNazioni;
      axios.get(link).then((response) => {
        me.nazioniOptions = response.data.data;
      });
    },
    onAddFarmaci(array, value) {
      if (value.farmaco !== "" && value.tempo !== "") {
        array.push({ farmaco: "", tempo: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Le Date di Inizio e Fine sono OBBLIGATORIE!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAttivaTabella() {
      let me = this;
      me.jsonData.listaFarmaci = [];
      if (me.jsonData.seiSettimanePrimaAssunzioneFarmaci === "SI") {
        me.jsonData.listaFarmaci.push({ farmaco: "", tempo: "" });
      }
    },
  },
};
</script>
