import Vue from "vue";
import Vuex from "vuex";
import DataanalysisTablesStore from "../app/dataanalysis/store";
import CotStore from "../app/cot/store";

Vue.use(Vuex);
const store = new Vuex.Store({
    namespaced: true,
    modules: {
        dataanalysisTablesStore: DataanalysisTablesStore,
        cotStore: CotStore,
    },
});

export default store;
