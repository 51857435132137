<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Sintomi" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sintomi</label>
          <span class="sa-data">{{ jsonData.presenzaSintomi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nausea</label>
          <span class="sa-data">{{ jsonData.nausea }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vomito</label>
          <span class="sa-data">{{ jsonData.vomito }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Diarrea</label>
          <span class="sa-data">{{ jsonData.diarrea }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Scariche</label>
          <span class="sa-data">{{ jsonData.diarreaNumeroScariche }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Febbre</label>
          <span class="sa-data">{{ jsonData.febbre }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Temperatura</label>
          <span class="sa-data">{{ jsonData.febbreTemperatura }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Dolori Addominali</label>
          <span class="sa-data">{{ jsonData.doloriAddominali }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
          <label class="sa-label-data">Specificare Altri Sintomi</label>
          <span class="sa-data">{{ jsonData.altriSintomi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data e Ora Comparsa Sintomi</label>
          <span class="sa-data">{{ formatDateTime(jsonData.dataOraComparsaSintomi) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Luogo di Consumo Del/i Pasto/i Sospetto/i</label>
          <span class="sa-data">{{ jsonData.luogoBanchetto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data di Consumo Del/i Pasto/i Sospetto/i</label>
          <span class="sa-data">{{ formatDate(jsonData.dataBanchetto) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Consumo Pasti su Mezzi di Trasporto:</label>
          <span class="sa-data">{{ jsonData.pastoConsumatoMezzoTrasporto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Descrizione Pasti:</label>
          <span class="sa-data">{{ jsonData.pastoConsumatoMezzoTrasportoDescrizione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Esercizio Commerciale Acquisto Alimento Sospetto:</label>
          <span class="sa-data">{{ jsonData.pastoConsumatoLuogoAcquisto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Partecipanti al Pasto Sospetto:</label>
          <span class="sa-data">{{ jsonData.numeroPersonePartecipantiConsumoPasto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Componenti Nucleo Familiare/Comunita Consumo Pasti in Comune:</label>
          <span class="sa-data">{{ jsonData.numeroPersoneConsumoPastiComuni }}</span>
        </b-col>
        <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Alimenti Consumati</label>
          <span class="sa-data">{{ jsonData.alimentiConsumati }}</span>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altre Persone Hanno Partecipato ai Pasti:</label>
          <span class="sa-data">{{ jsonData.invitati }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaInvitati" :fields="fieldsInvitati" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Luogo Consumo Pasto Fuori Casa:</label>
          <span class="sa-data">{{ jsonData.nomeGestoreReferentePastiFuoriCasa }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome Persona che Prepara il Pasto in Casa:</label>
          <span class="sa-data">{{ jsonData.nomePersonaPastiCasa }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Luogo Acquisto Alimenti 72 Ore Prima:</label>
          <span class="sa-data">{{ jsonData.acquistoAlimentiSettantadueOrePrima }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ipotesi Diagnostiche:</label>
          <span class="sa-data">{{ jsonData.ipotesiDiagnostiche }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Pasto Sospetto:</label>
          <span class="sa-data">{{ jsonData.pastoSospetto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tempo Incubazione:</label>
          <span class="sa-data">{{ jsonData.tempoIncubazione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Agente Infettante:</label>
          <span class="sa-data">{{ jsonData.agenteInfettante }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Nelle 72 ore precedenti l'insorgenza dei sintomi dove, con chi e quali alimenti ha cosumato" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-dettaglio-alimenti-component :listaDettaglioAlimenti="jsonData.listaDettaglioAlimenti" :isEdit="false"></indagine-epidemiologica-dettaglio-alimenti-component>
    </b-card>
    <b-card class="sa-card" header="Tasso Attacco Alimento Specifici" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-tassi-attacco-alimentari-component :listaTassiAttaccoAlimentari="jsonData.listaTassiAttaccoAlimentari" :isEdit="false"></indagine-epidemiologica-tassi-attacco-alimentari-component>
    </b-card>
    <b-card class="sa-card" header="Dati Ricovero" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricovero Ospedaliero</label>
          <span class="sa-data">{{ jsonData.ricoveroOspedaliero }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome Ospedale</label>
          <span class="sa-data">{{ jsonData.nomeOspedate }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Ora Ricovero</label>
          <span class="sa-data">{{ formatDateTime(jsonData.dataOraRicovero) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Coprocoltura</label>
          <span class="sa-data">{{ jsonData.coprocoltura }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Esito</label>
          <span class="sa-data">{{ jsonData.coprocolturaEsito }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Telefono Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.sanitarioNotificanteContatto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Notifica</label>
          <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaDettaglioAlimentiComponent from "../components/IndagineEpidemiologicaDettaglioAlimentiComponent.vue";
import IndagineEpidemiologicaTassiAttaccoAlimentariComponent from "../components/IndagineEpidemiologicaTassiAttaccoAlimentariComponent.vue";
export default {
  components: { IndagineEpidemiologicaDettaglioAlimentiComponent, IndagineEpidemiologicaTassiAttaccoAlimentariComponent },
  props: {
    // jsonData: {
    //   Type: Object,
    //   default: function () {
    //     return {};
    //   },
    // },
  },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveietossinfezionealimentare",
      currentPage: 1,
      perPage: 100,
      jsonData: {},
      fieldsInvitati: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        // {
        //   label: "Codice Fiscale",
        //   key: "codiceFiscale",
        //   thStyle: "width: 15rem",
        //   sortable: false,
        // },
        {
          label: "Cognome",
          key: "cognome",
          thStyle: "width: 15rem",
          sortable: false,
        },
        {
          label: "Nome",
          key: "nome",
          thStyle: "width: 15rem",
          sortable: false,
        },
        {
          label: "Domicilio",
          key: "domicilio",
          sortable: false,
        },
        {
          label: "Telefono",
          key: "telefono",
          thStyle: "width: 15rem",
          sortable: false,
        },
        {
          label: "Pasto",
          key: "pasto",
          thStyle: "width: 25rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  // watch: {
  //   jsonData: {
  //     handler(jsonData) {
  //       this.$emit("update", jsonData);
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    loadData() {
      let me = this;
      me.$emit("showmodalloading", true);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.$emit("showmodalloading", false);
        })
        .catch(() => {
          me.$emit("showmodalloading", false);
        });
    },
  },
};
</script>

<style></style>
