import Test from "../";

const routes = [
    {
        name: "TestList",
        path: "/testlist",
        component: Test.TestList,
    },
    {
        name: "TestTabsScroll",
        path: "/testtabscroll",
        component: Test.TestTabsScroll,
    },
];

export default routes;
