<template>
  <div>
    <b-card class="sa-card" header="A5. Circostanza che ha dato origine alle lesioni indicate nel quesito 4. Parte I (Codifica Supplementare)" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Codice ICD 10</label>
          <b-form-select v-model="jsonData.codiceIcdXMorteViolenta" :options="codiciIcdXOptions" :value="''" value-field="codice" text-field="descrizione" @input="onInputCodiceIcdX($event)" disabled></b-form-select>
        </b-col>
        <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Codice ICD 10</label>
          <b-form-input list="my-list-id" v-model="jsonData.codiceIcdXMorteViolenta"></b-form-input>
          <datalist id="my-list-id">
            <option v-for="size in codiciIcdXOptions" :key="size.id">{{ size.descrizione }}</option>
          </datalist>
        </b-col> -->
        <b-col xs="12" sm="12" md="8" lg="8" xl="8">
          <!-- <label class="sa-label-data">Descrizione ICD 10</label>
          <b-form-input v-model="jsonData.descrizioneCodificataIcdXMorteViolenta" disabled></b-form-input> -->

          <label class="sa-label-data">Descrizione ICD 10</label>
          <b-form-input list="my-list-id" v-model="jsonData.descrizioneCodificataIcdXMorteViolenta" @change="onInputDescrizioneIcdx()"></b-form-input>
          <datalist id="my-list-id">
            <option v-for="descrizione in codiciIcdXOptions" :key="descrizione.id">{{ descrizione.descrizione }}</option>
          </datalist>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Codice ICD 9</label>
          <!-- <b-form-select v-model="jsonData.icdIx" :options="codiciIcdIxOptions" :value="''" value-field="value" text-field="text" @input="onInputCodiceIcdIx"></b-form-select> -->
          <b-form-select v-model="jsonData.icdIx" :options="codiciIcdIxOptions" :value="''" value-field="value" text-field="text" disabled></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="8" lg="8" xl="8">
          <label class="sa-label-data">Descrizione ICD 9</label>
          <b-form-input v-model="jsonData.descrizioneCodificataIcdIx" disabled></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="A6. Modalita del traumatismo/avvelenamento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">A6. Modalita del traumatismo/avvelenamento</label>
          <b-form-select v-model="jsonData.modalitaTraumatismo" :options="modalitaTraumatismoOptions" :value="''" value-field="id" text-field="descrizione" @input="onInputModalitaTraumatismo"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">A6.1 Infortunio sul Lavoro</label>
          <b-form-select v-model="jsonData.infortunioLavoro" :options="YesNoOptions" :value="''" value-field="value" text-field="text" :disabled="infortunioLavoroDisabled"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="A7. In Caso di incidente da trasporto specificare anche" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">A7.1 Mezzo di trasporto</label>
          <b-form-input v-model="jsonData.mezzoTrasportoVittima"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">A7.2 Ruolo della vittima</label>
          <b-form-select v-model="jsonData.ruoloVittima" :options="ruoloVittimaOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">A7.3 Tipo Incidente</label>
          <b-form-input v-model="jsonData.tipoIncidente"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">A7.4 Scontro indicare l'oggetto/investimento indicare il veicolo coinvolto</label>
          <b-form-input v-model="jsonData.oggettoVeicoloCoinvolto"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">A8. Data dell'accidente, infortunio, suicidio, omicidio</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraAccidente" :disabled-date="notAfter" type="datetime"></date-picker>
        </b-col>

        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">A9. Luogo dell'evento che ha dato origine al traumatismo</label>
          <b-form-select v-model="jsonData.luogoAccidente" stacked :options="luogoOrigineTraumatismoOptions" :value="''" value-field="id" text-field="descrizione" @input="onInputLuogoAccidente"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Indicare 'Altri Luoghi'</label>
          <b-form-input v-model="jsonData.luogoAccidenteAltriLuoghi" :disabled="luogoAccidenteAltriLuoghiDisabled"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: { DatePicker },
  props: {
    jsonData: {
      type: Array[Object],
      default: function () {
        return {
          codiceIcdXMorteViolenta: "",
          descrizioneCodificataIcdXMorteViolenta: "",
          codiceIcdIx: "",
          descrizioneCodificataIcdIx: "",
          modalitaTraumatismo: "",
          infortunioLavoro: "",
          mezzoTrasportoVittima: "",
          ruoloVittima: "",
          tipoIncidente: "",
          oggettoVeicoloCoinvolto: "",
          dataOraAccidente: null,
          luogoAccidente: "",
          luogoAccidenteAltriLuoghi: "",
        };
      },
    },
  },
  mounted() {
    let me = this;
    me.loadDefaultData();
  },
  data() {
    return {
      pathResourceModalitaTraumatismo: "/rencamschedemortemodalitatraumatismo",
      pathResourceOrigineTraumatismo: "/rencamschedemorteluogoeventooriginetrauma",
      pathResourceRuoloVittima: "/rencamschedemorteruolovittima",
      pathResourceIcdX: "/serviziicdx",
      pathResourceIcdIx: "/serviziicdix/morteviolenta",
      infortunioLavoroDisabled: true,
      luogoAccidenteAltriLuoghiDisabled: true,
      luogoOrigineTraumatismoOptions: [{ id: "", descrizione: "-Seleziona Luogo Origine Traumatismo-" }],
      localitaDecessoOptions: [],
      modalitaTraumatismoOptions: [{ id: "", descrizione: "-Seleziona Modalita' Traumatismo-" }],
      ruoloVittimaOptions: [{ id: "", descrizione: "-Seleziona Ruolo Vittima-" }],
      luogoOption: [],
      codiceCapitolo: 20,
      statiGravidanzaOptions: [],
      luogoTraumatismoOption: [],
      YesNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      codiciIcdXOptions: [{ value: "", text: "-Seleziona Codice ICD 10-" }],
      codiciIcdIxOptions: [{ value: "", text: "-Seleziona Codice ICD 9-" }],
      filtro: { forPage: 10000, page: 1 },
    };
  },

  methods: {
    loadDefaultData() {
      let me = this;
      me.loadModalitaTraumatismo();
      me.loadLuogoOrigineTraumatismo();
      me.loadRuoloVittima();
      me.loadCodiciIcdX();
      // me.loadCodiciIcdIx();
    },
    onInputModalitaTraumatismo(value) {
      let me = this;
      if (value === "2") {
        me.infortunioLavoroDisabled = false;
      } else {
        me.infortunioLavoroDisabled = true;
        me.jsonData.infortunioLavoro = "";
      }
    },

    loadModalitaTraumatismo() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceModalitaTraumatismo;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.modalitaTraumatismoOptions = response.data.data.list;
        me.modalitaTraumatismoOptions.unshift({ id: "", descrizione: "-Seleziona Modalita' Traumatismo-" });
        // response.data.data.list.forEach((element) => {
        //     me.modalitaTraumatismoOptions.push({ value: element.id, text: element.descrizione });
        // });
      });
    },
    loadLuogoOrigineTraumatismo() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceOrigineTraumatismo;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.luogoOrigineTraumatismoOptions = response.data.data.list;
        me.luogoOrigineTraumatismoOptions.unshift({ id: "", descrizione: "-Seleziona Luogo Origine Traumatismo-" });
      });
    },
    loadRuoloVittima() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceRuoloVittima;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.ruoloVittimaOptions = response.data.data.list;
        me.ruoloVittimaOptions.unshift({ id: "", descrizione: "-Seleziona Ruolo Vittima-" });
      });
    },

    onInputLuogoAccidente(value) {
      let me = this;
      if (value === "9") {
        me.luogoAccidenteAltriLuoghiDisabled = false;
      } else {
        me.luogoAccidenteAltriLuoghiDisabled = true;
        me.jsonData.luogoAccidenteAltriLuoghi = "";
      }
    },
    notAfter: function (date) {
      return date > Date.now();
    },
    loadCodiciIcdX() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceIcdX;
      axios.get(link, { params: { capitolo: me.codiceCapitolo } }).then((response) => {
        me.codiciIcdXOptions = [];
        response.data.data.list.forEach((element) => {
          me.codiciIcdXOptions.push({ codice: element.codice, descrizione: element.codice + " (" + element.descrizione + ")" });
        });
        me.codiciIcdXOptions.unshift({ codice: "", descrizione: "-Selezionare il codice ICD-10-" });
      });
    },
    onInputCodiceIcdX(codiceIcdX) {
      let me = this;
      if (codiceIcdX !== "" && codiceIcdX !== null) {
        me.codiciIcdXOptions.forEach((element) => {
          if (element.codice === codiceIcdX) {
            let descrizione = /\(([^)]+)\)/.exec(element.descrizione);
            me.jsonData.descrizioneCodificataIcdXMorteViolenta = descrizione[1];
          }
        });
      } else {
        me.jsonData.descrizioneCodificataIcdXMorteViolenta = "";
      }
    },
    // loadCodiciIcdIx() {
    //     let me = this;
    //     let link = process.env.VUE_APP_PATH_API + me.pathResourceIcdIx;
    //     axios.get(link, { params: me.filtro }).then((response) => {
    //         response.data.data.list.forEach((element) => {
    //             me.codiciIcdIxOptions.push({ value: element.codice, text: element.codice });
    //         });
    //         me.codiciIcdIxOptions.unshift({ value: "", text: "-Seleziona Codice ICD 9-" });
    //     });
    // },
    // onInputCodiceIcdIx(codiceIcdX) {
    //     let me = this;
    //     me.codiciIcdIxOptions.forEach((element) => {
    //         if (element.value === codiceIcdX) {
    //             me.jsonData.descrizioneCodificataIcdIx = element.text;
    //         } else {
    //             me.jsonData.descrizioneCodificataIcdIx = "";
    //         }
    //     });
    // },

    onInputDescrizioneIcdx() {
      let me = this;
      let trovato = false;
      // console.log(me.jsonData.descrizioneCodificataIcdXMorteViolenta);
      me.codiciIcdXOptions.forEach((element) => {
        if (me.jsonData.descrizioneCodificataIcdXMorteViolenta === element.descrizione) {
          trovato = true;
          me.jsonData.codiceIcdXMorteViolenta = element.codice;
        }
      });
      if (!trovato) {
        me.jsonData.descrizioneCodificataIcdXMorteViolenta = "";
        me.jsonData.codiceIcdXMorteViolenta = "";
      }
    },
  },
};
</script>

<style></style>
