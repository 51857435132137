import { render, staticRenderFns } from "./PazienteTerapieAttive.vue?vue&type=template&id=40fc666d&scoped=true"
import script from "./PazienteTerapieAttive.vue?vue&type=script&lang=js"
export * from "./PazienteTerapieAttive.vue?vue&type=script&lang=js"
import style0 from "./PazienteTerapieAttive.vue?vue&type=style&index=0&id=40fc666d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fc666d",
  null
  
)

export default component.exports