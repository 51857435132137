<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Informazioni Anagrafiche relative al caso" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Caso:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.tipoCaso" :options="tipoCasoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Installata App Immuni:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.appImmuniAttiva" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Operatore Sanitario:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.operatoreSanitario" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sede:</label>
          <b-form-input v-model="jsonData.operatoreSanitarioSede" :disabled="chekCondition(jsonData.operatoreSanitario, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Telefono:</label>
          <b-form-input v-model="jsonData.operatoreSanitarioSedeTelefono" :disabled="chekCondition(jsonData.operatoreSanitario, 'SI')"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Composizione del Nucleo abitativo" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaFamiliari" :fields="fieldsFamiliari" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
        <template #cell(index)="jsonData">
          {{ jsonData.index + 1 }}
        </template>
        <template #cell(cognomeNome)="item">
          <b-form-input v-model="item.item.cognomeNome" placeholder="Cognome Nome"></b-form-input>
        </template>
        <template #cell(gradoParentela)="item">
          <b-form-input v-model="item.item.gradoParentela" placeholder="Grado Parentela"></b-form-input>
        </template>
        <template #cell(eta)="item">
          <b-form-input v-model="item.item.eta" placeholder="Eta"></b-form-input>
        </template>
        <template #cell(occupazione)="item">
          <b-form-input v-model="item.item.occupazione" placeholder="Occupazione"></b-form-input>
        </template>
        <template #cell(actions)="row">
          <b-button v-if="row.index == jsonData.listaFamiliari.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFamiliare(jsonData.listaFamiliari, row.item)">
            <b-icon icon="plus"></b-icon>
          </b-button>
          <b-button v-if="row.index !== jsonData.listaFamiliari.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteFamiliare(jsonData.listaFamiliari, row)">
            <b-icon icon="trash"></b-icon>
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="Informazioni Cliniche e di Laboratorio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Sintomi:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.tipoSintomi" :options="tipoSintomiOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Sintomi:</label>
          <date-picker v-model="jsonData.dataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="!checkDoubleCondition(jsonData.tipoSintomi, 'PAUCI-SINTOMATICO', 'SINTOMATICO', 'AND')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sintomi Simil-Influenzali:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomatologiaSimileInfluenza" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Febbre (>37.5):</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.febbre" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tosse:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.tosse" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Difficolta Respiratoria:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.difficoltaRespiratoria" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Mal di Gola:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.malGola" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altro:</label>
          <b-form-input v-model="jsonData.altro"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Condizioni Cliniche Gravi:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.condizioniClinicheGravi" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricovero Terapia Intensiva:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricoveroTerapiaIntensiva" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ospedale:</label>
          <b-form-input v-model="jsonData.luogoRicovero" :disabled="chekCondition(jsonData.ricoveroTerapiaIntensiva, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data:</label>
          <date-picker v-model="jsonData.dataRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.ricoveroTerapiaIntensiva, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Trasferito da Altro Ospedale:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.trasferitoAltroOspedale" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.ricoveroTerapiaIntensiva, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Struttura Provenienza:</label>
          <b-form-input v-model="jsonData.trasferitoAltroOspedaleStrutturaProvenienza" :disabled="chekCondition(jsonData.trasferitoAltroOspedale, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vaccino influenza 2020/21:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.vaccinoInfluenzaDuemilaventiVentuno" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Mese:</label>
          <b-form-input v-model="jsonData.vaccinoInfluenzaDuemilaventiVentunoMese" :disabled="chekCondition(jsonData.vaccinoInfluenzaDuemilaventiVentuno, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Anno:</label>
          <b-form-input v-model="jsonData.vaccinoInfluenzaDuemilaventiVentunoAnno" :disabled="chekCondition(jsonData.vaccinoInfluenzaDuemilaventiVentuno, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Isolamento Domiciliare Fiduciario:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.isolamentoDomiciliareFiduciario" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dove:</label>
          <b-form-input v-model="jsonData.isolamentoDomiciliareFiduciarioDove" :disabled="chekCondition(jsonData.isolamentoDomiciliareFiduciario, 'SI')"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Esami Laboratorio SARS-Cov-2 Effettuati" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Prima Indagine Tipo:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.primaIndagineTipo" :options="tipoIndagineOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Prima Indagine Data:</label>
          <date-picker v-model="jsonData.primaIndagineDataPrelievo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
          <!-- <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.primaIndagineTipo" :options="tipoIndagineOptions" value="" value-field="value" text-field="text"></b-form-select> -->
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Prima Indagine Esito:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.primaIndagineEsito" :options="esitoIndagineOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Seconda Indagine Tipo:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.secondaIndagineTipo" :options="tipoIndagineOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Seconda Indagine Data:</label>
          <date-picker v-model="jsonData.secondaIndagioneDataPrelievo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="!chekCondition(jsonData.secondaIndagineTipo, '')"></date-picker>
          <!-- <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.primaIndagineTipo" :options="tipoIndagineOptions" value="" value-field="value" text-field="text"></b-form-select> -->
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Seconda Indagine Esito:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.secondaIndagineEsito" :options="esitoIndagineOptions" value="" value-field="value" text-field="text" :disabled="!chekCondition(jsonData.secondaIndagineTipo, '')"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Epidemiologici" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Contatto Caso Confermato:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.contattoCasoConfermato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nominativo:</label>
          <b-form-input v-model="jsonData.contattoCasoConfermatoNominativo" :disabled="chekCondition(jsonData.contattoCasoConfermato, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Telefono:</label>
          <b-form-input v-model="jsonData.contattoCasoConfermatoContatto" :disabled="chekCondition(jsonData.contattoCasoConfermato, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Contatto:</label>
          <date-picker v-model="jsonData.contattoCasoConfermatoData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.contattoCasoConfermato, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Presente in area con casi confermati:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.presenzaAreaRischio" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nazione:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.presenzaAreaRischioNazione" :options="nazioniOptions" value="" value-field="codiceIstat" text-field="comune" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Provincia:</label>
          <b-form-input v-model="jsonData.presenzaAreaRischioProvincia" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Citta:</label>
          <b-form-input v-model="jsonData.presenzaAreaRischioCitta" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Arrivo:</label>
          <date-picker v-model="jsonData.presenzaAreaRischioDataArrivo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Partenza:</label>
          <date-picker v-model="jsonData.presenzaAreaRischioDataRientro" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Provincia Rientro:</label>
          <b-form-select v-model="jsonData.presenzaAreaRischioLuogoRientroProvincia" :options="provinceRientroOptions" :value="null" value-field="sigla" text-field="provincia" @change="loadProvinceRientroChange" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Comune Rientro:</label>
          <b-form-select v-model="jsonData.presenzaAreaRischioLuogoRientro" :options="comuniRientroOptions" :value="''" value-field="codiceIstat" text-field="comune" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Mezzo di Trasporto:</label>
          <b-form-input v-model="jsonData.presenzaAreaRischioRientroMezzoTrasporto" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Ora:</label>
          <date-picker v-model="jsonData.presenzaAreaRischioRientroMezzoTrasportoData" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Compagnia:</label>
          <b-form-input v-model="jsonData.presenzaAreaRischioRientroMezzoTrasportoCompagnia" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Id Viaggio:</label>
          <b-form-input v-model="jsonData.presenzaAreaRischioRientroMezzoTrasportoIdViaggio" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Numero Posto:</label>
          <b-form-input v-model="jsonData.presenzaAreaRischioRientroMezzoTrasportoPosto" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Altro Tipo Trasporto:</label>
          <b-form-input v-model="jsonData.presenzaAreaRischioRientroMezzoTrasportoAltro" :disabled="chekCondition(jsonData.presenzaAreaRischio, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Spostamento Mezzi Pubblici/Privati:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.spostamentoMezziPubbliciPrivati" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dettaglio Mezzi Pubblici/Privati:</label>
          <b-form-input v-model="jsonData.spostamentoMezziPubbliciDettagli" :disabled="chekCondition(jsonData.spostamentoMezziPubbliciPrivati, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Viaggi Gruppo/Organizzati Italia:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.partecipatoViaggiGruppoItalia" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tour Operator:</label>
          <b-form-input v-model="jsonData.partecipatoViaggiGruppoItaliaTourOperator" :disabled="chekCondition(jsonData.partecipatoViaggiGruppoItalia, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Numero Viaggiatori:</label>
          <b-form-input v-model="jsonData.partecipatoViaggiGruppoItaliaNumeroViaggiatori" :disabled="chekCondition(jsonData.partecipatoViaggiGruppoItalia, 'SI')"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dettaglio Soggiorno Citta' Italiane" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaViaggiItalia" :fields="fieldsCitta" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
        <template #cell(index)="jsonData">
          {{ jsonData.index + 1 }}
        </template>
        <template #cell(provinciaDescrizione)="item">
          <b-form-select v-if="item.index == jsonData.listaViaggiItalia.length - 1" v-model="item.item.provinciaDescrizione" :options="provinceSoggiornoItaliaOptions" :value="null" value-field="sigla" text-field="provincia" @change="loadProvinceSoggiornoItaliaChange"></b-form-select>
          <span v-else class="sa-data">{{ item.item.provinciaDescrizione }}</span>
        </template>
        <template #cell(citta)="item">
          <b-form-select v-if="item.index == jsonData.listaViaggiItalia.length - 1" v-model="item.item.citta" :options="comuniSoggiornoItaliaOptions" :value="''" value-field="codiceIstat" text-field="comune" @change="onSetCittaDescrizione(item.item)"></b-form-select>
          <span v-else class="sa-data">{{ item.item.cittaDescrizione }}</span>
        </template>
        <template #cell(alloggio)="item">
          <b-form-input v-if="item.index == jsonData.listaViaggiItalia.length - 1" v-model="item.item.alloggio" placeholder="Alloggio"></b-form-input>
          <span v-else class="sa-data">{{ item.item.alloggio }}</span>
        </template>
        <template #cell(telefono)="item">
          <b-form-input v-if="item.index == jsonData.listaViaggiItalia.length - 1" v-model="item.item.telefono" placeholder="telefono"></b-form-input>
          <span v-else class="sa-data">{{ item.item.telefono }}</span>
        </template>
        <template #cell(dataArrivo)="item">
          <date-picker v-if="item.index == jsonData.listaViaggiItalia.length - 1" v-model="item.item.dataArrivo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
          <span v-else class="sa-data">{{ formatDate(item.item.dataArrivo) }}</span>
        </template>
        <template #cell(dataPartenza)="item">
          <date-picker v-if="item.index == jsonData.listaViaggiItalia.length - 1" v-model="item.item.dataPartenza" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
          <span v-else class="sa-data">{{ formatDate(item.item.dataPartenza) }}</span>
        </template>
        <template #cell(actions)="row">
          <b-button v-if="row.index == jsonData.listaViaggiItalia.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddCitta(jsonData.listaViaggiItalia, row.item)">
            <b-icon icon="plus"></b-icon>
          </b-button>
          <b-button v-if="row.index !== jsonData.listaViaggiItalia.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteCitta(jsonData.listaViaggiItalia, row)">
            <b-icon icon="trash"></b-icon>
          </b-button>
        </template>
      </b-table>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Partecipato Meeting/Convegni/Raduni:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.partecipatoMeetingConvegniRaduni" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Dal:</label>
          <date-picker v-model="jsonData.partecipatoMeetingConvegniRaduniDal" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.partecipatoMeetingConvegniRaduni, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Al:</label>
          <date-picker v-model="jsonData.partecipatoMeetingConvegniRaduniAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.partecipatoMeetingConvegniRaduni, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Luogo:</label>
          <b-form-input v-model="jsonData.partecipatoViaggiGruppoItaliaNumeroViaggiatori" :disabled="chekCondition(jsonData.partecipatoMeetingConvegniRaduni, 'SI')"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Quattordici giorni precedenti" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Lavoro/Frequenza Struttura Sanitaria:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.contattoQuattordiciGiorniPrima" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nome Struttura:</label>
          <b-form-input v-model="jsonData.contattoQuattordiciGiorniPrimaNomeStruttura" :disabled="chekCondition(jsonData.contattoQuattordiciGiorniPrima, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Recapito:</label>
          <b-form-input v-model="jsonData.contattoQuattordiciGiorniPrimaRecapiti" :disabled="chekCondition(jsonData.contattoQuattordiciGiorniPrima, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Lavoro/Frequenza Struttura Sanitaria:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.contattoProvenienteZoneRischio" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Follow Up" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Esito:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.followupEsito" :options="followUpEsitiOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data:</label>
          <date-picker v-model="jsonData.followupEsitoData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.followupEsito === ''"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Contact tracing - Lista contatti stretti" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
        <template #cell(index)="jsonData">
          {{ jsonData.index + 1 }}
        </template>
        <template #cell(dataContatto)="item">
          <date-picker v-model="item.item.dataContatto" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </template>
        <template #cell(cognome)="item">
          <b-form-input v-model="item.item.cognome" placeholder="Cgnome"></b-form-input>
        </template>
        <template #cell(nome)="item">
          <b-form-input v-model="item.item.nome" placeholder="Nome"></b-form-input>
        </template>
        <template #cell(telefono)="item">
          <b-form-input v-model="item.item.telefono" placeholder="telefono"></b-form-input>
        </template>
        <template #cell(comune)="item">
          <b-form-input v-model="item.item.comune" placeholder="Comune"></b-form-input>
        </template>
        <template #cell(actions)="row">
          <b-button v-if="row.index == jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddContatto(jsonData.listaContatti, row.item)">
            <b-icon icon="plus"></b-icon>
          </b-button>
          <b-button v-if="row.index !== jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteContatto(jsonData.listaContatti, row)">
            <b-icon icon="trash"></b-icon>
          </b-button>
        </template>
      </b-table>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Note:</label>
          <b-form-textarea v-model="jsonData.note" no-resize rows="6" style="margin-top: 5px"></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="true" @update="onUpdateJsonData" :disabled="chekCondition(jsonData.ricoveroTerapiaIntensiva, 'SI')"></indagine-epidemiologica-dati-notifica-component>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { IndagineEpidemiologicaDatiNotificaComponent, DatePicker }, //, DatePicker
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveiecovid",
      pathResourceComuni: "/comuni",
      pathResourceProvince: "/province",
      linkback: null,
      currentPage: 1,
      perPage: 100,
      showModalLoading: false,
      linkNazioni: "/nazioni",
      disableSintomi: true,
      jsonData: {
        tipoCaso: "",
        appImmuniAttiva: "",
        operatoreSanitario: "",
        operatoreSanitarioSede: "",
        operatoreSanitarioSedeTelefono: "",
        tipoSintomi: "",
        dataInsorgenzaSintomi: null,
        sintomatologiaSimileInfluenza: "",
        febbre: "",
        tosse: "",
        difficoltaRespiratoria: "",
        malGola: "",
        altro: "",
        condizioniClinicheGravi: "",
        ricoveroTerapiaIntensiva: "",
        luogoRicovero: "",
        dataRicovero: null,
        trasferitoAltroOspedale: "",
        trasferitoAltroOspedaleStrutturaProvenienza: "",
        vaccinoInfluenzaDuemilaventiVentuno: "",
        vaccinoInfluenzaDuemilaventiVentunoMese: "",
        vaccinoInfluenzaDuemilaventiVentunoAnno: "",
        isolamentoDomiciliareFiduciario: "",
        isolamentoDomiciliareFiduciarioDove: "",
        primaIndagineTipo: "",
        primaIndagineDataPrelievo: null,
        primaIndagineEsito: "",
        secondaIndagineTipo: "",
        secondaIndagioneDataPrelievo: null,
        secondaIndagineEsito: "",
        contattoCasoConfermato: "",
        contattoCasoConfermatoNominativo: "",
        contattoCasoConfermatoContatto: "",
        contattoCasoConfermatoData: null,
        presenzaAreaRischio: "",
        presenzaAreaRischioNazione: "",
        presenzaAreaRischioCitta: "",
        presenzaAreaRischioProvincia: "",
        presenzaAreaRischioDataArrivo: null,
        presenzaAreaRischioDataRientro: null,
        presenzaAreaRischioLuogoRientro: "",
        presenzaAreaRischioProvinciaRientro: "",
        presenzaAreaRischioRientroMezzoTrasporto: "",
        presenzaAreaRischioRientroMezzoTrasportoData: null,
        presenzaAreaRischioRientroMezzoTrasportoCompagnia: "",
        presenzaAreaRischioRientroMezzoTrasportoIdViaggio: "",
        presenzaAreaRischioRientroMezzoTrasportoPosto: "",
        presenzaAreaRischioRientroMezzoTrasportoAltro: "",
        spostamentoMezziPubbliciPrivati: "",
        spostamentoMezziPubbliciDettagli: "",
        partecipatoViaggiGruppoItalia: "",
        partecipatoViaggiGruppoItaliaTourOperator: "",
        partecipatoViaggiGruppoItaliaNumeroViaggiatori: "",
        partecipatoMeetingConvegniRaduni: "",
        partecipatoMeetingConvegniRaduniDal: null,
        partecipatoMeetingConvegniRaduniAl: null,
        partecipatoMeetingConvegniRaduniLuogo: "",
        contattoQuattordiciGiorniPrima: "",
        contattoQuattordiciGiorniPrimaNomeStruttura: "",
        contattoQuattordiciGiorniPrimaRecapiti: "",
        contattoProvenienteZoneRischio: "",
        followupEsito: "",
        followupEsitoData: null,
        note: "",
        sanitarioNotificante: "",
        dataNotifica: null,
        sanitarioNotificanteContatto: "",
        aslAppartenenza: "",
        emailNotificante: "",
        idAnagrafica: "",
        presenzaAreaRischioLuogoRientroProvincia: "",
        listaContatti: [],
        listaFamiliari: [],
        listaViaggiItalia: [],
      },
      provinceRientroOptions: [{ sigla: "", provincia: "-Seleziona Provincia-" }],
      comuniRientroOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
      provinceSoggiornoItaliaOptions: [{ sigla: "", provincia: "-Seleziona Provincia-" }],
      comuniSoggiornoItaliaOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
      fieldsFamiliari: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Cognome Nome",
          key: "cognomeNome",
          sortable: false,
        },
        {
          label: "Grado Parentela",
          key: "gradoParentela",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Eta",
          key: "eta",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Occupazione",
          key: "occupazione",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsCitta: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Provincia",
          key: "provinciaDescrizione",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Citta",
          key: "citta",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Alloggio",
          key: "alloggio",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Telefono",
          key: "telefono",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Data Arrivo",
          key: "dataArrivo",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Data Partenza",
          key: "dataPartenza",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsContatti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Data Contatto",
          key: "dataContatto",
          sortable: false,
          thStyle: "width: 15rem",
        },
        {
          label: "Cognome",
          key: "cognome",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Nome",
          key: "nome",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Telefono",
          key: "telefono",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Comune",
          key: "comune",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      nazioniOptions: [],
      tipoCasoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SOSPETTO", text: "SOSPETTO" },
        { value: "CONFERMATO", text: "CONFERMATO" },
      ],
      tipoIndagineOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "TNF", text: "TNF" },
        { value: "SIEROLOGICO", text: "SIEROLOGICO" },
      ],
      esitoIndagineOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSITIVO", text: "POSITIVO" },
        { value: "NEGATIVO", text: "NEGATIVO" },
        { value: "NON ANCORA NOTO", text: "NON ANCORA NOTO" },
      ],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      siNoNonNotoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      tipoSintomiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "ASINTOMATICO", text: "ASINTOMATICO" },
        { value: "PAUCI-SINTOMATICO", text: "PAUCI-SINTOMATICO" },
        { value: "SINTOMATICO", text: "SINTOMATICO" },
      ],
      followUpEsitiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "ASSENZA SINTOMATOLOGIA", text: "ASSENZA SINTOMATOLOGIA" },
        { value: "GUARITO CON TNF NEGATIVO", text: "GUARITO CON TNF NEGATIVO" },
        { value: "DECESSO", text: "DECESSO" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.jsonData.id = me.id;
    let malattiaInfettivaSegnalazione = sessionStorage.getItem("MalattiaInfettivaSegnalazione");
    let malattiaInfettiva = {};
    if (malattiaInfettivaSegnalazione !== null && malattiaInfettivaSegnalazione !== "null") {
      malattiaInfettiva = JSON.parse(malattiaInfettivaSegnalazione);
      me.setDatiMalattia(malattiaInfettiva);
    }
    sessionStorage.setItem("MalattiaInfettivaSegnalazione", null);
    if (me.id !== "-1") {
      me.linkback = "/malattieinfettive/indagineepidemiologica/covid/view/" + me.id + "?idPaziente=" + me.idPaziente;
    } else {
      me.linkback = "/malattieinfettive/all";
    }
    me.loadNazioni();
    me.loadProvinceRientro();
    me.loadProvinceSoggiornoItalia();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;

      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
          me.loadComuniRientro(me.jsonData.presenzaAreaRischioLuogoRientroProvincia);
          me.jsonData.listaFamiliari.push({ cognomeNome: "", gradoParentela: "", eta: "", occupazione: "" });
          me.jsonData.listaViaggiItalia.push({ provinciaDescrizione: "", citta: "", alloggio: "", telefono: "", dataArrivo: null, dataPartenza: null });
          me.jsonData.listaContatti.push({ dataContatto: null, nome: "", cognome: "", telefono: "", comune: "" });
        })
        .catch(() => {
          me.setDatiMedico();
          me.showModalLoading = false;
          me.jsonData.listaFamiliari.push({ cognomeNome: "", gradoParentela: "", eta: "", occupazione: "" });
          me.jsonData.listaViaggiItalia.push({ provinciaDescrizione: "", citta: "", alloggio: "", telefono: "", dataArrivo: null, dataPartenza: null });
          me.jsonData.listaContatti.push({ dataContatto: null, nome: "", cognome: "", telefono: "", comune: "" });
        });
    },
    loadNazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/provincia/EE";
      me.nazioniOptions = [];
      axios
        .get(link)
        .then((response) => {
          me.nazioniOptions = response.data.data;
          me.nazioniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Nazione-" });
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    loadProvinceRientro() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
      axios.get(link).then((response) => {
        me.provinceRientroOptions = response.data.data;
        me.provinceRientroOptions.unshift({ sigla: "", provincia: "- Seleziona Provincia-" });
      });
    },
    loadProvinceRientroChange(value) {
      let me = this;
      me.provinceRientroOptions.forEach((provincia) => {
        if (provincia.sigla === value) {
          me.setComuniRientro(provincia.sigla);
        }
      });
    },
    setComuniRientro(siglaProvincia) {
      let me = this;
      console.log(siglaProvincia);
      me.jsonData.presenzaAreaRischioLuogoRientroProvincia = siglaProvincia;
      me.loadComuniRientro(siglaProvincia);
    },
    loadComuniRientro(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/provincia/" + sigla;
      axios.get(link).then((response) => {
        me.comuniRientroOptions = [];
        me.comuniRientroOptions = response.data.data;
        me.comuniRientroOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
      });
    },
    loadProvinceSoggiornoItalia() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
      axios.get(link).then((response) => {
        me.provinceSoggiornoItaliaOptions = response.data.data;
        me.provinceSoggiornoItaliaOptions.unshift({ sigla: "", provincia: "- Seleziona Provincia-" });
      });
    },
    loadProvinceSoggiornoItaliaChange(value) {
      let me = this;
      me.provinceSoggiornoItaliaOptions.forEach((provincia) => {
        if (provincia.sigla === value) {
          me.setComuniSoggiornoItalia(provincia.sigla);
        }
      });
    },
    setComuniSoggiornoItalia(siglaProvincia) {
      let me = this;
      console.log(siglaProvincia);
      // me.jsonData.provinciaSoggiornoItaliaDescrizione = siglaProvincia;
      me.loadComuniSoggiornoItalia(siglaProvincia);
    },
    loadComuniSoggiornoItalia(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/provincia/" + sigla;
      axios.get(link).then((response) => {
        me.comuniSoggiornoItaliaOptions = [];
        me.comuniSoggiornoItaliaOptions = response.data.data;
        me.comuniSoggiornoItaliaOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
      });
    },
    onSetCittaDescrizione(value) {
      let me = this;
      me.comuniSoggiornoItaliaOptions.forEach((element) => {
        if (element.codiceIstat === value.citta) {
          value.cittaDescrizione = element.comune;
        }
      });
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onAddFamiliare(array, value) {
      if (value.cognomeNome !== "" && value.gradoParentela !== "" && value.eta !== "" && value.occupazione !== "") {
        array.push({ cognomeNome: "", gradoParentela: "", eta: "", occupazione: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Campi OBBLIGATORI!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDeleteFamiliare(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddCitta(array, value) {
      // console.log(value);
      if (value.citta !== "" && value.alloggio !== "" && value.telefono !== "" && value.dataArrivo !== null && value.dataPartenza !== null) {
        array.push({ provinciaDescrizione: "", citta: "", alloggio: "", telefono: "", dataArrivo: null, dataPartenza: null });
      } else {
        this.$bvModal
          .msgBoxOk("Campi OBBLIGATORI!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDeleteCitta(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddContatto(array, value) {
      if (value.dataContatto !== null && value.nome !== "" && value.cognome !== "" && value.telefono !== "" && value.comune !== "") {
        array.push({ dataContatto: null, nome: "", cognome: "", telefono: "", comune: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Campi OBBLIGATORI!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDeleteContatto(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
