<template>
    <sa-page-layout :toolbarVisible="false" :btnSaveVisible="false" :btnAnnullaVisible="false" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <identity-user-info-component @befoarLoadData="befoarLoadData" @afterLoadData="afterLoadData"></identity-user-info-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import IdentityUserInfoComponent from "../components/IdentityUserInfoComponent.vue";
export default {
    components: { SaPageLayout, IdentityUserInfoComponent },
    data() {
        return {
            linkback: null,
            jsonData: {},
            showModalLoading: false,
            pathResource: null,
        };
    },
    methods: {
        befoarLoadData() {
            this.showModalLoading = true;
        },
        afterLoadData() {
            this.showModalLoading = false;
        },
    },
};
</script>

<style></style>
