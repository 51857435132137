import Vue from "vue";
import Vuex from "vuex";
import { useFavicon } from "@vueuse/core";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSaTemplate from "./template/";
import i18n from "./i18n";
import axios from "axios";
import CountryFlag from "vue-country-flag";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// Import Bootstrap an BootstrapVue CSS files (order is important)
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@/assets/css/sa-bootstrap.css";
import "@/assets/css/style.css";
import "vue2-datepicker/index.css";
import "@/assets/css/sa-layout.css";
import "@/assets/css/index.scss";
import * as VueGoogleMaps from "vue2-google-maps";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueSaTemplate);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("country-flag", CountryFlag);
Vue.config.productionTip = false;
Vue.prototype.appConfig = {};
Vue.prototype.user = {};
Vue.use(Vuex); //test
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBCPFWahMd954JkjxV9P5KnKVmEMCqeRQs",
        libraries: "places", // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        v: "3.26",
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    installComponents: true,
    dynamicLoad: false,
});
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
axios.get("config/config.json").then((baseresponse) => {
    let cssTemplateName = baseresponse.data ? baseresponse.data.css : "gea";
    let file = document.createElement("link");
    let favicon = "css/" + cssTemplateName + "/images/favicon.ico";
    file.rel = "stylesheet";
    file.href = "css/" + cssTemplateName + "/" + cssTemplateName + ".css";
    document.head.appendChild(file);
    let messages = {
        therapy: { lblDrug: "Test" },
        paziente: { titolo: "Titolo di Prova" },
        teleconsulto: { lblTitle: "Questa è una prova di caricamento dinamico" },
    };
    useFavicon(favicon, {
        baseUrl: "/",
        rel: "icon",
    });
    Vue.prototype.appConfig = baseresponse.data;
    let language = Vue.prototype.appConfig.language;
    let uri = window.location.href.split("?");
    if (uri.length == 2) {
        let vars = uri[1].split("&");
        vars.forEach(function (v) {
            let tmp = v.split("=");
            if (tmp.length == 2) {
                if (tmp[0] === "language") {
                    language = tmp[1];
                }
            }
        });
    }
    i18n.locale = language;
    if (!language) {
        router.push({ path: this.$route.path, query: { ...this.$route.query, language: Vue.prototype.appConfig.language } });
        router.go();
    } else {
        let link = process.env.VUE_APP_PATH_API + "/traduora?language=" + language;
        axios.get(link).then((response) => {
            messages = response.data;
            i18n.setLocaleMessage(language, messages);
            new Vue({
                i18n,
                router,
                store,
                created() {},
                mounted: function () {},
                render: (h) => h(App),
            }).$mount("#app");
        });
    }
});

Vue.filter("toCurrency", function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
    });
    return formatter.format(value);
});

// axios
//     .get("config/config.json")
//     .then((response) => {
//         Vue.prototype.appConfig = response.data;
//         new Vue({
//             i18n,
//             router,
//             render: (h) => h(App),
//         }).$mount("#app");
//     })
//     .catch((e) => {
//         console.log(e);
//         //   this.errors.push(e);
//     });
