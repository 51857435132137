import { render, staticRenderFns } from "./EmergenzaEdit.vue?vue&type=template&id=446b4369&scoped=true"
import script from "./EmergenzaEdit.vue?vue&type=script&lang=js"
export * from "./EmergenzaEdit.vue?vue&type=script&lang=js"
import style0 from "./EmergenzaEdit.vue?vue&type=style&index=0&id=446b4369&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446b4369",
  null
  
)

export default component.exports