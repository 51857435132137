import { render, staticRenderFns } from "./EcocarotidiEdit.vue?vue&type=template&id=5b6ab094&scoped=true"
import script from "./EcocarotidiEdit.vue?vue&type=script&lang=js"
export * from "./EcocarotidiEdit.vue?vue&type=script&lang=js"
import style0 from "./EcocarotidiEdit.vue?vue&type=style&index=0&id=5b6ab094&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b6ab094",
  null
  
)

export default component.exports