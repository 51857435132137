<template>
    <div class="sa-flex-page">
        <div class="sa-flex-page-header"><slot name="header"></slot></div>
        <div class="sa-flex-page-body"><slot name="body"></slot></div>
        <div class="sa-flex-page-footer"><slot name="footer"></slot></div>
    </div>
</template>

<script>
export default {};
</script>

<style>
.sa-flex-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: transparent;
}

.sa-flex-page-header {
    background-color: transparent;
}

.sa-flex-page-body {
    background-color: transparent;
    flex: 1;
}

.sa-flex-page-footer {
    background-color: transparent;
}
</style>
