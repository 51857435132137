<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <div class="sa-list-component-header">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <div class="sa-list-component-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(action)>
                            <b-icon icon="check-square-fill" variant="normal"></b-icon>
                            <!-- <b-button size="sm" class="no-text" variant="outline-info" @click="onModifica(row)">
                <i class="fas fa-edit"></i>
              </b-button>
              <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onPrint">
                <b-icon icon="printer"></b-icon>
              </b-button> -->
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/cotschedevalutazione",
            filtro: {},
            items: [],
            listKey: 0,
            fields: [
                {
                    label: "Tipo Scheda",
                    key: "tipoScheda",
                    sortable: true,
                },
                {
                    label: "",
                    key: "action",
                    thStyle: "width: 3rem",
                },
            ],
        };
    },
    props: {
        idPropostaAccesso: String,
    },
    mounted() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idScheda = me.idPropostaAccesso;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.rows = response.data.data.recordsNumber;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onPrint() {
            console.log("Eccomi");
        },
        onModifica() {
            console.log("Modifica");
        },
    },
};
</script>

<style></style>
